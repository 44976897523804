import React from 'react';
import '../scss/managers-opinions.scss';
import Title from './title';
import { ManagerInterface } from '../models/Manager';
import Carrousel from './carrousel';

export interface TalentOpinionsProps {}

const TALENTS: ManagerInterface[] = [
  {
    name: 'Leydi Rodríguez',
    description:
      '“Me he desarrollado profesionalmente en compañía de Proyectat con las mejores herramientas y con el mejor equipo del país”.',
    image: '/images/talent_1.png',
    jobTitle: 'Empresaria<br />Davida Ltda',
  },
  {
    name: 'Alejandro Paco Monroy',
    description:
      '“Proyectat me ha brindado la capacitación y orientación para desarrollar mis potencialidades y habilidades, Esto nos ha permitido desarrollar una gran comunidad”.',
    image: '/images/talent_2.png',
    jobTitle: 'Consultor Patrimonial en Riesgo e Inversión',
  },
  {
    name: 'Marcela Berdugo',
    description:
      '“Proyectat es oportunidades para todos. Es poder tener el respaldo de líderes que se preocupan por ti y que quieren verte crecer día a día. La comunidad te abre un sin fin de posibilidades que te llevan a a avanzar profesionalmente.”.',
    image: '/images/talent_3.png',
    jobTitle: 'Empresaria<br />Avanz Seguros',
  },
  {
    name: 'Jorge Salcedo',
    description:
      '“Hacer parte de Proyectat es estar rodeado de personas muy talentosas, profesionales y sobre todo muy humanas. En proyectat he encontrado las herramientas necesarias para continuar creciendo”.',
    image: '/images/talent_4.png',
    jobTitle: 'Empresario<br />Finanzas Seguras',
  },
  {
    name: 'Diana Bautista',
    description:
      '“Proyectat es el mejor aliado estratégico que he tenido en mi formación integral de liderazgo para apoyar a mis clientes en sus finanzas personales”.',
    image: '/images/talent_5.png',
    jobTitle: 'Empresaria DSI<br />Grupo Asegurador',
  },
];

const TalentOpinions: React.FC<TalentOpinionsProps> = props => {
  return (
    <section className="Managers__container Talent__container">
      <Title
        text="Desarrollo de Talentos Proyectat®"
        className="Talent__opinions_title"
      />
      <Carrousel source={TALENTS} />
    </section>
  );
};

export default TalentOpinions;
