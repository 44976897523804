import React from 'react';
import '../scss/talent-message.scss';

export interface TalentMessage {}

const TalentMessage: React.FC<TalentMessage> = props => {
  return (
    <section className="Talent__message-container">
      <p className="Talent__message-paragraph desktop__justify">
        Ahora tu puedes hacer parte de una comunidad en la que somos
        especialistas en el desarrollo de equipos de alto desempeño con nuestras
        propias herramientas y sistema de ventas validado, nuestro principal
        objetivo es facilitar el progreso de los talentos independientes
        apoyándolos desde el inicio con la estrategia, acompañamiento en campo,
        desarrollo permanente de talento y capacitación constante con aliados
        internacionales; convirtiéndonos de esta manera, en la mejor opción para
        la profesionalización de Talentos independientes en el sector asegurador
        a nivel nacional.
      </p>
      <div className="Talent__image-container">
        <img
          src="/images/33.png"
          alt="Imagen de bienvenida al talento"
          className="Talent__image-img"
        />
      </div>
    </section>
  );
};

export default TalentMessage;
