import React from 'react';
import '../scss/benefits.scss';
import Title from './title';
import TalentImage from './talent-image';

export interface Benefits {}

interface BenefitInterface {
  image: string;
  description: string;
}

const BENEFITS: BenefitInterface[] = [
  {
    description:
      'Somos creadores de productos disruptivos en el mercado con impacto social, apoyados en importantes aliados del sector asegurador.',
    image: '/images/34.png',
  },
  {
    description:
      'Al ser parte de nuestra Agencia Master creas tu propio negocio contando desde un inicio con nuestro apoyo para desarrollar una estrategia y modelo de negocio exitoso.',
    image: '/images/35.png',
  },
  {
    description:
      'Contarás con soporte y desarrollo comercial, que te permite manejar la línea completa de creación del equipo para lograr alto desempeño, desarrollo permanente del talento, crecimiento sostenido y rentabilidad de tu negocio.',
    image: '/images/36.png',
  },
  {
    description:
      'Logramos crear un equipo desde la estrategia hasta la implementación o también tomamos equipos existentes para llevarlos al siguiente nivel.',
    image: '/images/37.png',
  },
  {
    description:
      'Contamos con un equipo diverso e interdisciplinario que nos permite abordar las diferentes temáticas con especialidad para la búsqueda de las soluciones más efectivas.',
    image: '/images/38.png',
  },
  {
    description:
      'Creemos en un modelo escalable que vaya a la medida de tus sueños individuales y lo que quieras alcanzar.',
    image: '/images/39.png',
  },
];

const STEPS: string[] = [
  'Nuestro primer grupo de graduados nativos en 100% canal digital',
  'Activaciones diarias para toda la comunidad para abrir fronteras con los mayores referentes nacionales e internacionales del sector.',
  'Un completo canal de atracción del Talento.',
  'Acceso a un programa de capacidad profesional en seguros y formación virtual certificado por EU School y Proyectat apuntando a fortalecer los conocimientos técnicos y el desarrollo de habilidades blandas.',
  'Creamos cursos especializados de liderazgo.',
  'En el Café de expertos compartimos en comunidad nuestras mejores prácticas.',
  'El primer MBA en Latinoamérica con Especialidad en Liderazgo y Management de Seguros de Vida Individual creado con nuestra metodología y certificado por European School of Leadership.',
];

const Benefits: React.FC<Benefits> = props => {
  return (
    <section className="Benefits-container">
      <Title
        text="Por qué ser parte de Proyectat®"
        className="Benefits-title"
      />
      {BENEFITS.map((BENEFIT, index) => (
        <div className="Benefit__item" key={`benefit-item-${index}`}>
          <div className="Benefit__item-image">
            <img src={BENEFIT.image} alt="" className="Benefit__item-img" />
          </div>
          <p
            className="Benefit__item-paragraph"
            dangerouslySetInnerHTML={{ __html: BENEFIT.description }}
          ></p>
        </div>
      ))}

      <TalentImage />

      <section className="Stepper-container">
        <Title text="Abordamos el Cambio" className="Stepper-title" />
        <p className="Stepper-subtitle">
          En ProyectaT estamos convencidos que el mundo cambió y nosotros
          nacimos diferentes, es por ello que, en la contingencia actual,
          mejoramos nuestros canales de comunicación y adherimos nuevas
          estrategias para facilitar el sostenimiento de nuestra gestión en una
          nueva realidad. Contamos con:
        </p>
        <div className="Stepper-steps">
          {STEPS.map((STEP, index) => (
            <div className="Stepper__item" key={`stepper-item-${index}`}>
              <div
                className={
                  (index === 0 ? 'first__counter' : '') +
                  ' Stepper__item-counter'
                }
              >
                <div className="Stepper__item-dots">
                  <div className="Stepper__item-column"></div>
                </div>
                <p className="Stepper__item-circle">{index + 1}</p>
              </div>
              <p
                className="Stepper__item-paragraph"
                dangerouslySetInnerHTML={{ __html: STEP }}
              ></p>
            </div>
          ))}
        </div>
      </section>
    </section>
  );
};

export default Benefits;
