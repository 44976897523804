import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Banner from '../components/banner';
import TalentMessage from '../components/talent-message';
import Benefits from '../components/benefits';
import TalentBanner from '../components/talent-banner';
import TalentOpinions from '../components/talent-opinions';
import JoinUsBanner from '../components/join-us-banner';

export interface TalentProps {}

const Talent: React.FC<TalentProps> = () => (
  <div className="screen__container">
    <Layout>
      <SEO title="Talento Proyectat" />
      <Banner
        title="Una compañía para crecer juntos"
        subtitle="Comienza a construir tu negocio de la mano de los mejores."
        image="/images/Proyectat-07.png"
        mobileBackgroundColor="#D6E848"
        hasButton={false}
        dark={true}
      ></Banner>
      <TalentMessage />
      <JoinUsBanner />
      <Benefits />
      <TalentBanner />
      <TalentOpinions />
    </Layout>
  </div>
);

export default Talent;
