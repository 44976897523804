import React from 'react';
import '../scss/talent-image.scss';

export interface TalentImageProps {}

const TalentImage: React.FC<TalentImageProps> = props => {
  return (
    <section className="Talent__image">
      <p className="Talent__image-paragraph">
        En Proyectat contamos con el programa de formación comercial más
        completo del país.
      </p>
    </section>
  );
};

export default TalentImage;
