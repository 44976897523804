import React from 'react';
import '../scss/join-us-banner.scss';
import Button from './button';
import { connect } from 'react-redux';
import { handleShowModalPostulation } from '../redux/actions';

export interface JoinUsBannerProps {
  dispatch: any;
}

const JoinUsBanner: React.FC<JoinUsBannerProps> = props => {
  const showModalPostulation = () =>
    props.dispatch(handleShowModalPostulation(true));

  return (
    <section className="JoinUs__container">
      <div className="JoinUs__description">
        <p className="JoinUs__description-paragraph desktop__justify">
          Es por ello que nos encontramos en la constante apertura de incorporar
          profesionales emprendedores que quieran trabajar de manera
          independiente en la colocación de productos financieros no bancarios con un gran
          impacto social y metodología de trabajo mixta (digital y directa) y
          que quieran ser Talento ProyectaT.
        </p>
        <Button
          fontSize="21px"
          paddingLeft="60px"
          paddingRight="60px"
          className="JoinUs__button"
          onClickEvent={showModalPostulation}
        >
          ¡Haz parte de nuestro equipo!
        </Button>
      </div>
      <img src="/images/Proyectat-14.png" alt="" className="JoinUs__image" />
    </section>
  );
};

export default connect(state => ({ showModalQuote: state.showModalQuote }))(
  JoinUsBanner
);
