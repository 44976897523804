import React from 'react';
import '../scss/talent-banner.scss';
import Button from './button';
import { connect } from 'react-redux';
import { handleShowModalPostulation } from '../redux/actions';

export interface TalentBanner {
  dispatch: any;
}

const TalentBanner: React.FC<TalentBanner> = props => {
  const showModalPostulation = () =>
    props.dispatch(handleShowModalPostulation(true));

  return (
    <section className="Postulate__container">
      <div className="Postulate__description">
        <h3 className="Postulate__description-title">
          Comienza a construir tu futuro con nosotros
        </h3>
        <p className="Postulate__description-paragraph">
          ¡Da el primer paso y envía tu postulación a nuestra área de talento
        </p>
        <Button
          fontSize="21px"
          paddingLeft="97px"
          paddingRight="75px"
          className="Postulate__button"
          dark={true}
          onClickEvent={showModalPostulation}
        >
          Postularme ahora
        </Button>
      </div>
      <img src="/images/Proyectat-11.png" alt="" className="Postulate__image" />
    </section>
  );
};

export default connect(state => ({ showModalQuote: state.showModalQuote }))(
  TalentBanner
);
